<template>
  <b-card header="Edit Question">
    <poll-form
      v-if="pollLoaded"
      :submit="editPoll"
      :poll="poll"
    />
  </b-card>
</template>
<script>
import PollForm from '@/common/components/Polls/PollForm.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'EditPoll',
  components: { PollForm },
  data() {
    return {
      poll: { answers: [{ body: '' }, { body: '' }, { body: '' }] },
      pollLoaded: false,
    }
  },
  created() {
    this.getPoll()
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    getPoll() {
      this.$activities.get(`/internalops/poll-session/${this.$route.params.liveId}/poll/${this.$route.params.id}`).then(res => {
        const { poll } = res.data.data
        const { answers } = res.data.data
        this.poll = {
          ...poll,
          answers,
        }
        this.$set(this.poll, 'right_answer_temp', this.getRightAnswerIndex())

        this.pollLoaded = true
      })
    },
    editPoll() {
      return this.$activities.put(`/internalops/poll-session/${this.$route.params.liveId}/poll/${this.$route.params.id}`, {
        question: this.poll.question,
        valid_from: this.poll.valid_from,
        valid_to: this.poll.valid_to,
        answers: this.poll.answers,
      }).then(() => {
        this.successfulOperationAlert('Question is edited successfully')
        this.$router.push({ name: 'polls-list' })
      })
    },
    getRightAnswerIndex() {
      return this.poll.answers.findIndex(answer => answer.is_true) + 1
    },
    redirectToPollList() {
      this.$router.push({ name: 'polls-list' })
    },
  },
}
</script>
<style lang="scss">

</style>
